import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mobility-v2"
    }}>{`Mobility v2`}</h1>
    <p>{`Aggregation API for shared mobility services.`}</p>
    <h2 {...{
      "id": "gbfs"
    }}>{`GBFS`}</h2>
    <p>{`Currently, we offer data from a number of shared mobility operators in several Norwegian cities.`}</p>
    <p>{`All of our data is served in `}<a parentName="p" {...{
        "href": "https://github.com/NABSA/gbfs/blob/v2.2/gbfs.md"
      }}>{`version 2.3`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://github.com/NABSA/gbfs/blob/v3.0/gbfs.md"
      }}>{`version 3.0`}</a>{` of the General Bikeshare Feed Specification (GBFS)
format which is a standardized data feed for shared mobility systems.`}</p>
    <h3 {...{
      "id": "list-the-gbfs-endpoint-for-all-operators"
    }}>{`List the GBFS endpoint for all operators`}</h3>
    <p>{`All currently implemented operators and their respective gbfs endpoints will be listed under the root endpoint.`}</p>
    <h4 {...{
      "id": "http-request-for-gbfs-v30"
    }}>{`HTTP Request for GBFS v3.0`}</h4>
    <p>{`This is the `}<inlineCode parentName="p">{`manifest.json`}</inlineCode>{` endpoint that was introduced in GBFS v3.0. It lists all systems available,
in all available versions.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.entur.io/mobility/v2/gbfs/v3/manifest.json`}</inlineCode></p>
    <h5 {...{
      "id": "example-response"
    }}>{`Example response`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "last_updated": "2024-10-01T10:32:30.786+00:00",
    "ttl": 3600,
    "version": "3.0",
    "data": {
        "datasets": [
            {
                "system_id": "voistavanger",
                "versions": [
                    {
                        "version": "2.3",
                        "url": "https://api.entur.io/mobility/v2/gbfs/v2/voistavanger/gbfs"
                    },
                    {
                        "version": "3.0",
                        "url": "https://api.entur.io/mobility/v2/gbfs/v3/voistavanger/gbfs"
                    }
                ]
            }
        ]
    }
}
`}</code></pre>
    <h4 {...{
      "id": "http-request-for-gbfs-v23"
    }}>{`HTTP Request for GBFS v2.3`}</h4>
    <p>{`This is a custom endpoint that lists GBFS v2.3 endpoints.`}</p>
    <p><inlineCode parentName="p">{`GET https://api.entur.io/mobility/v2/gbfs/v2`}</inlineCode></p>
    <h5 {...{
      "id": "example-response-1"
    }}>{`Example response`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "systems": [
        {
            "id": "oslobysykkel",
            "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/gbfs"
        },
        {
            "id": "boltoslo",
            "url": "https://api.entur.io/mobility/v2/gbfs/boltoslo/gbfs"
        }
    ]
}
`}</code></pre>
    <h3 {...{
      "id": "gbfs-information-for-an-operator-gbfs-v30"
    }}>{`GBFS information for an operator (GBFS v3.0)`}</h3>
    <p>{`Get the different GBFS endpoints for a specific operator in GBFS v3.0.`}</p>
    <h4 {...{
      "id": "http-request"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://api.entur.io/mobility/v2/gbfs/v3/{system}/gbfs`}</inlineCode></p>
    <p>{`Where `}<inlineCode parentName="p">{`{system}`}</inlineCode>{` is one of the last part of the system id, for instance `}<inlineCode parentName="p">{`voistavanger`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "example-response-2"
    }}>{`Example response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "last_updated": "2023-02-10T09:30:00.000+00:00",
    "ttl": 86400,
    "version": "3.0",
    "data": {
        "feeds": [
            {
                "name": "system_information",
                "url": "https://api.entur.io/mobility/v2/gbfs/v3/voistavanger/system_information"
            },
            {
                "name": "vehicle_types",
                "url": "https://api.entur.io/mobility/v2/gbfs/v3/voistavanger/vehicle_types"
            },
            {
                "name": "gbfs",
                "url": "https://api.entur.io/mobility/v2/gbfs/v3/voistavanger/gbfs"
            },
            {
                "name": "system_alerts",
                "url": "https://api.entur.io/mobility/v2/gbfs/v3/voistavanger/system_alerts"
            },
            {
                "name": "system_pricing_plans",
                "url": "https://api.entur.io/mobility/v2/gbfs/v3/voistavanger/system_pricing_plans"
            },
            {
                "name": "system_regions",
                "url": "https://api.entur.io/mobility/v2/gbfs/v3/voistavanger/system_regions"
            },
            {
                "name": "vehicle_status",
                "url": "https://api.entur.io/mobility/v2/gbfs/v3/voistavanger/vehicle_status"
            },
            {
                "name": "geofencing_zones",
                "url": "https://api.entur.io/mobility/v2/gbfs/v3/voistavanger/geofencing_zones"
            }
        ]
    }
}
`}</code></pre>
    <h3 {...{
      "id": "gbfs-information-for-an-operator-gbfs-v23"
    }}>{`GBFS information for an operator (GBFS v2.3)`}</h3>
    <p>{`Get the different GBFS endpoints for a specific operator in GBFS v2.3.`}</p>
    <h4 {...{
      "id": "http-request-1"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://api.entur.io/mobility/v2/gbfs/v2/{system}/gbfs`}</inlineCode></p>
    <p>{`Where `}<inlineCode parentName="p">{`{system}`}</inlineCode>{` is one of the last part of the system id, for instance `}<inlineCode parentName="p">{`oslobysykkel`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "example-response-3"
    }}>{`Example response`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "last_updated": 1610375175,
    "ttl": 15,
    "version": "2.3",
    "data": {
        "nb": {
            "feeds": [
                {
                    "name": "system_information",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/system_information"
                },
                {
                    "name": "station_information",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/station_information"
                },
                {
                    "name": "station_status",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/station_status"
                },
                {
                    "name": "system_pricing_plans",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/system_pricing_plans"
                },
                {
                    "name": "vehicle_types",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/vehicle_types"
                }
            ]
        }
    }
}
`}</code></pre>
    <h2 {...{
      "id": "graphql"
    }}>{`GraphQL`}</h2>
    <p>{`We also offer a client-centric GraphQL API as a way to aggregate data across all providers. It currently offers two
entrypoints to finding entities near a location: vehicles and stations.`}</p>
    <p>{`The GraphQL endpoint's URL is `}<a parentName="p" {...{
        "href": "https://api.entur.io/mobility/v2/graphql"
      }}>{`https://api.entur.io/mobility/v2/graphql`}</a></p>
    <p>{`An interactive IDE for exploring this API is available at `}<a parentName="p" {...{
        "href": "https://api.entur.io/graphql-explorer/mobility"
      }}>{`https://api.entur.io/graphql-explorer/mobility`}</a></p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`This API is open under NLOD licence, however, it is required that all consumers identify themselves by using the header ET-Client-Name. Entur will deploy strict rate-limiting policies on API-consumers who do not identify with a header and reserves the right to block unidentified consumers. The structure of ET-Client-Name should be: "company-application"`}</p>
    <p>{`Header examples:`}</p>
    <ul>
      <li parentName="ul">{`"brakar-journeyplanner"`}</li>
      <li parentName="ul">{`"fosen_utvikling-departureboard"`}</li>
      <li parentName="ul">{`"nor_way_bussekspress-nwy-app"`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      